import { createWebHistory, createRouter } from 'vue-router'
import i18n from './i18n'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:locale/',
      component: () => import('./templates/main/MainTemplate.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('./views/home/HomeView.vue'),
        },
        {
          path: 'products',
          name: 'products',
          component: () => import('./views/products/ProductsView.vue'),
        },
        {
          path: 'platform',
          name: 'platform',
          component: () => import('./views/platform/PlatformView.vue'),
        },
        {
          path: 'about',
          name: 'aboutUs',
          component: () => import('./views/about/AboutUsView.vue'),
        },
      ],
    },
  ],
})

router.beforeEach((to, _, next) => {
  const locale = (to.params.locale as string) || 'en'
  // Redirect to home if no route name or locale is not supported
  if (!to.name) next({ name: 'home', params: { locale } })
  const SUPPORTED_LOCALES = ['en', 'th']
  if (!SUPPORTED_LOCALES.includes(locale)) {
    return next({ name: to.name as string, params: { locale: 'en' } })
  }
  i18n.global.locale.value = locale as 'en' | 'th'
  next()
})

router.afterEach((to, from) => {
  // Smoothly scroll to top if navigating to a new page
  if (from.name !== to.name) window.scrollTo({ top: 0, behavior: 'smooth' })
})

export default router
