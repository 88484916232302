import { createApp } from 'vue'
import router from './router'
import App from './App.vue'

// i18n
import i18n from './i18n'
// CSS
import './assets/style.css'
import './assets/primevue.css'
// Font Awesome
import '@fortawesome/fontawesome-free/js/all'
// PrimeVue
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import ToastService from 'primevue/toastservice'

/* GSAP */
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

/* Axios */
import axios from 'axios'
axios.defaults.baseURL = import.meta.env.VITE_API_ENDPOINT

/* Create Vue.js App */
const app = createApp(App)
app.use(ToastService)
app.use(router).use(i18n).use(PrimeVue).mount('#app')
